import React, { Fragment, useState } from 'react';
import { useNotify, useDataProvider, required, FormDataConsumer, SimpleForm } from 'react-admin';
import _ from 'lodash';
import { connect } from 'react-redux';
import HeaderButton from './HeaderButton';
import { Typography } from '@material-ui/core';
import { SUPPORT_URL } from '../../constants';
import * as analytics from '../../analytics';
import { makeStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import ChatIcon from '@material-ui/icons/Chat';
import FormToolbar from '../../Components/Toolbars/FormToolbar';
import FormLongTextInput from '../../Components/Inputs/Form/FormLongTextInput';
import DialogBase from '../../Components/Dialogs/DialogBase';
import withWidth from '@material-ui/core/withWidth';

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        fontSize: '2rem',
        marginLeft: theme.spacing(2),
    },
    form: {
        '& > div': {
            padding: 0,
        },

        '& legend': {
            display: 'none !important',
        },
    },
    dialog: {
        width: '450px',
        maxWidth: '95%',
        '& .MuiDialogTitle-root': {
            fontSize: '14px',
            wordBreak: 'break-all',
        },
        '& .MuiDialogActions-root': {
            display: 'none',
        },
    },
    link: {
        textDecoration: 'none',
    },
}));

const HeaderHelpDropdown = ({ basicProfileComplete, width }) => {
    const [openDialog, setOpenDialog] = useState(false);

    const notify = useNotify();
    const dataProvider = useDataProvider();
    const classes = useStyles();

    const handleAnalytics = type => {
        let action = '';

        switch (type) {
            case 'contact-support-navbar':
                action = `clicked ‘contact support’ in top nav bar`;
                break;
            case 'send-message':
                action = `clicked 'send message' in contact support pop up`;
                break;
            case 'help-centre':
                action = `clicked 'help centre' in contact support pop up`;
                break;
            case 'header-help-centre':
                action = `clicked 'help centre' in header`;
                break;
            default:
                action = 'Request callback';
        }

        analytics.track(action, { category: 'Support' });
    };

    const handleMenuClick = e => {
        handleAnalytics('contact-support-navbar');
        setOpenDialog(true);
    };

    const handleMenuClose = () => {
        setOpenDialog(false);
    };

    const handleCallbackClick = async formData => {
        await dataProvider.create('professional/request-callback', formData);

        handleAnalytics('send-message');

        handleMenuClose();

        notify('A member of our team will be in touch shortly!');
    };

    return (
        <>
            <HeaderButton
                onClick={() => {
                    window.open(SUPPORT_URL.HELP_CENTRE, '_blank');
                    handleAnalytics('header-help-centre');
                }}
                startIcon={<HelpIcon/>}
                label={width === 'xs' ? '' : 'Help center'}
            />
            <HeaderButton
                onClick={handleMenuClick}
                startIcon={<ChatIcon/>}
                label={width === 'xs' ? '' : 'Contact support'}
            />
            {basicProfileComplete && openDialog ? (
                <DialogBase
                    className={classes.dialog}
                    open={openDialog}
                    onClose={handleMenuClose}
                    title={<Typography className={classes.dialogTitle}>Let us help</Typography>}
                >
                        <SimpleForm
                            save={handleCallbackClick}
                            toolbar={<FormToolbar label="Send message" />}
                        >
                            <Typography onClick={() => handleAnalytics('help-centre')}>
                                To help resolve your query faster, have you taken a look at our
                                <a className={classes.link} target="_blank" href={SUPPORT_URL.HELP_CENTRE}>
                                    &nbsp;Help Centre
                                </a>
                                ?
                            </Typography>
                            <Fragment>
                                <Typography>
                                    Which aspect of VouchedFor are you having trouble with?
                                </Typography>
                                <FormLongTextInput
                                    label=""
                                    source="callback_aspect"
                                    rows="5"
                                    validate={[required('Please tell us more')]}
                                />
                            </Fragment>
                        </SimpleForm>
                </DialogBase>
            ) : null}
        </>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        basicProfileComplete: _.get(basicInfo, 'permissions.basic_profile_complete'),
    };
};

export default connect(mapStateToProps)(withWidth()(HeaderHelpDropdown));
