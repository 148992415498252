import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ReviewDownloadPdfButton from './ReviewDownloadPdfButton';
import SimpleList from '../../Components/Lists/SimpleList';
import VerifyAndRejectButton from './VerifyAndRejectReviewButtons';
import { onWorkflowStart } from '../../constants';

import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import withStyles from '@material-ui/core/styles/withStyles';
import NotificationIcon from '../../Components/NotificationIcon';
import { vfColours } from '../../MyTheme';

const styles = () => ({
    email: {
        fontSize: 'smaller',
    },

    notificationIconSmall: {
        marginLeft: 4,
        width: 10,
        minWidth: 10,
        height: 10,
    }
});

const ReviewListSmall = (props) => {
    const shouldShowVerifyAndRejectButtons = record => {
        if (props.type === 'review' || props.type === 'first_impression') {
            return onWorkflowStart(record);
        }

        return false;
    };

    const renderPrimaryText = record => {
        const { primaryTextKey, type } = props
        const name = get(record, primaryTextKey, 'Anon')
        const readAt = get(record, 'read_at')

        const readThis = type.replaceAll('_', ' ')

        return (
            <>
                {!readAt
                    && <NotificationIcon
                        title={`Read this ${readThis}`}
                        className={props.classes.notificationIconSmall}
                        colors={{
                            backgroundColor: vfColours.cherryRed,
                            color: vfColours.white,
                        }}
                    />
                }
                Reviewer: {name}
            </>
        );
    };

    const renderSecondaryText = record => {
        const { secondaryTextKey, classes } = props;
        const email = get(record, secondaryTextKey, 'Email not specified');

        return (
            <div>
                <Typography className={classes.email}>{email}</Typography>
                {renderVerifyAndRejectButton(record)}
            </div>
        );
    };

    const renderVerifyAndRejectButton = record => {
        if (!shouldShowVerifyAndRejectButtons(record)) return null;

        return (
            <>
                <br />
                <VerifyAndRejectButton
                    record={record}
                    verifyProps={{
                        variant: 'contained',
                        style: { marginRight: 8 },
                    }}
                    reviewType={props.type}
                />
            </>
        );
    };

    const renderTertiaryText = record => {
        if (shouldShowVerifyAndRejectButtons(record)) {
            return <ErrorIcon color="error" />;
        }

        return record.state_description;
    };

    return (
        <div>
            <SimpleList
                primaryText={renderPrimaryText}
                secondaryText={renderSecondaryText}
                tertiaryText={renderTertiaryText}
                {...props}
            />
            <ReviewDownloadPdfButton />
        </div>
    )
}

ReviewListSmall.propTypes = {
    type: PropTypes.oneOf(['review', 'firstImpression', null]),
}

export default withStyles(styles)(ReviewListSmall);
