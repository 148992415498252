import React from 'react'
import { get } from 'lodash'
import { usePermissions } from 'react-admin'
import { Datagrid, TextField, DateField } from 'react-admin'
import BoldWhenUnreadWrapper from '../../Components/DataGrid/BoldWhenUnreadWrapper';
import CardContent from '@material-ui/core/CardContent'
import ReviewDownloadPdfButton from './ReviewDownloadPdfButton'
import ShareReviewButton from './ShareReviewButton'
import VerifyAndRejectReviewButtons from './VerifyAndRejectReviewButtons'
import UnreadField from '../../Components/DataGrid/UnreadField';


const Rating = ({ record, className }) => {
    const isTransferredClientReview = get(record, 'is_transferred_client_review', false);
    const averageRating = get(record, 'average_rating', 0);

    return (
        <span className={className}>
            { isTransferredClientReview ? 'n/a' : averageRating }
        </span>
    );
};

const BoldWhenUnreadDateField = BoldWhenUnreadWrapper(DateField);
BoldWhenUnreadDateField.defaultProps = DateField.defaultProps;

const BoldWhenUnreadTextField = BoldWhenUnreadWrapper(TextField);
BoldWhenUnreadTextField.defaultProps = DateField.defaultProps;

const BoldWhenUnreadRatingField = BoldWhenUnreadWrapper(Rating);
BoldWhenUnreadRatingField.defaultProps = Rating.defaultProps;

const ReviewListMedium = ({ dispatch, ...rest }) => {
    const { permissions } = usePermissions();

    return (
        <div>
            <CardContent>
                <Datagrid {...rest} actions={<div />}>
                    <UnreadField label="Unread" title="Read this review" source="read_at" />
                    <BoldWhenUnreadDateField label="Date" source="submitted_at" />
                    <BoldWhenUnreadTextField label="Reviewer name" source="customer.name" />
                    <BoldWhenUnreadTextField label="Reviewer email" source="customer.email" />
                    <BoldWhenUnreadRatingField label="Rating" sortable={false}/>
                    <BoldWhenUnreadTextField label="Review Status" source="state_description" sortable={false} />
                    <VerifyAndRejectReviewButtons reviewType="review"/>
                    {get(permissions, 'elevation_private_mode') ? null : (
                        <ShareReviewButton />
                    )}
                </Datagrid>
                <ReviewDownloadPdfButton />
            </CardContent>
        </div>
    );
};

export default ReviewListMedium;
