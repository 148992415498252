import React from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { setSidebarVisibility } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { closeSharePopover } from '../../utils';
import { Typography } from '@material-ui/core';
import { Zoom } from '@material-ui/core';
import { DISABLED_MENU_ELEVATION_TOOLTIP, ELEVATION_SELL_PAGE_URL, ELEVATION_SIDEBAR_GKAS } from '../../constants';
import NotificationIcon from '../../Components/NotificationIcon';
import { vfColours } from '../../MyTheme';
import Tooltip from '@material-ui/core/Tooltip';
import { ExternalLink, Link } from '../../Components/Links';

const useStyles = makeStyles(theme => ({
    active: {
        color: theme.palette.secondary.main,
        '& .MuiTypography-root': {
            color: theme.palette.secondary.main
        }
    },

    label: {
        '& > span': {
            margin: 0,
        },
    },

    icon: {
        color: 'inherit',
        minWidth: theme.spacing(5),
    },

    tooltipText: {
        padding: '1rem',
        fontSize: '0.8rem',
        color: theme.palette.error.main,
    },

    disabled: {
        color: theme.palette.tertiary.dark,
    },

    sidebarLabel: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    notification: {
        minWidth: 20,
    }
}));

const SidebarListItemButton = ({ classes, onClick, label, active, disabled }) => {
    return (
        <ListItem
            button
            className={cn({
                [classes.active]: active,
                [classes.disabled]: disabled,
            })}
            onClick={onClick}
        >
            <ListItemText
                className={cn({
                    [classes.active]: active,
                    [classes.label]: true,
                    [classes.disabled]: disabled}
                )}
                primary={label}
            />
        </ListItem>
    );
};

const DisabledTooltipMessage = ({ classes, isElevationSidebar }) => {
    if (isElevationSidebar) {
        return (
            <Typography className={classes.tooltipText}>
                {DISABLED_MENU_ELEVATION_TOOLTIP}
                <ExternalLink
                    href={ELEVATION_SELL_PAGE_URL}
                    target={'_blank'}
                >
                    &nbsp;Find out more.
                </ExternalLink>
            </Typography>
        )
    }

    return (
        <Typography className={classes.tooltipText}>
            To access this feature you need to
            <Link href={`/payment`}>
                &nbsp;upgrade to Verified.
            </Link>
        </Typography>
    )
}

const LabelWithNotifications = ({ label, gka, classes, notifications }) => {
    return (
        <span className={classes.sidebarLabel}>
            {label}

            {(notifications?.missingGender === true) && gka === 'profile' &&
                <NotificationIcon
                    title={'Needs details'}
                    symbol={"!"}
                    className={cn('needs-details', classes.notification)}
                />
            }

            {(notifications?.unreadFeedback !== 0) && gka === 'manage-feedback' &&
                <NotificationIcon
                    title={'Unread feedback'}
                    symbol={notifications.unreadFeedback}
                    className={cn('unread-feedback', classes.notification)}
                    colors={{ backgroundColor: vfColours.cherryRed, color: vfColours.white }}
                />
            }
        </span>
    );
}

const SidebarListItem = ({ href, label, gka, active, history, onClick, external, disabled, notifications }) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const onMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isElevationSidebar = ELEVATION_SIDEBAR_GKAS.includes(gka);

    const handleClick = () => {
        if (disabled) {
            return;
        }

        if (onClick) {
            onClick();

            return;
        }

        if (active) {
            return;
        }

        closeSharePopover();

        if (onMobile) {
            dispatch(setSidebarVisibility(false));
        }

        if (external) {
            window.open(href);
        } else {
            history.push(href);
        }
    };

    return (
        <>
            {disabled ? (
                <Tooltip
                    title={<DisabledTooltipMessage classes={classes} isElevationSidebar={isElevationSidebar} />}
                    placement="top"
                    TransitionComponent={Zoom}
                    interactive
                    arrow
                >
                    <div>
                        <SidebarListItemButton
                            active={active}
                            disabled={disabled}
                            label={
                                <LabelWithNotifications gka={gka} label={label} classes={classes} notifications={notifications} />
                            }
                            classes={classes}
                            onClick={handleClick}
                        />
                    </div>
                </Tooltip>
            ) : (
                <SidebarListItemButton
                    active={active}
                    disabled={disabled}
                    label={
                        <LabelWithNotifications gka={gka} label={label} classes={classes} notifications={notifications} />
                    }
                    classes={classes}
                    onClick={handleClick}
                />
            )}
        </>
    );
};

export default withRouter(SidebarListItem);
