import React from 'react';
import PageTabs from '../../AppLayout/PageTabs';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { get } from 'lodash'
import NotificationIcon from '../../Components/NotificationIcon';
import { vfColours } from '../../MyTheme';

const styles = theme => ({
    root: {
        marginTop: theme.spacing(2),
    },
});

const notificationColor = {
    backgroundColor: vfColours.cherryRed,
    color: vfColours.white,
};

const ManageFeedbackTabs = ({ classes, notifications }) => {
    const routes = [
        {
            label: 'My Reviews',
            link: '/reviews',
            icon: notifications.unreadReviews
                ? <NotificationIcon
                    title={'Unread reviews'}
                    className={'unread-reviews'}
                    symbol={notifications.unreadReviews}
                    colors={notificationColor}
                />
                : null,
        },
        {
            label: 'First Impressions',
            link: '/first-impressions',
            icon: notifications.unreadFirstImpressions
                ? <NotificationIcon
                    title={'Unread first impressions'}
                    className={'unread-reviews'}
                    symbol={notifications.unreadFirstImpressions}
                    colors={notificationColor}
                />
                : null,
        },
        {
            label: 'Annual Feedback',
            link: '/review-setting',
        },
    ];
    
    return (
        <div className={classes.root}>
            <PageTabs routes={routes} />
        </div>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        notifications: {
            unreadReviews: get(basicInfo, 'unread_reviews_count', 0),
            unreadFirstImpressions: get(basicInfo, 'unread_first_impressions_count', 0),
        },
    }
}

export default withStyles(styles)(connect(mapStateToProps)(ManageFeedbackTabs));
