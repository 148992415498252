import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { vfColours } from '../../MyTheme';
import NotificationIcon from '../NotificationIcon';

const UnreadField = ({ title, ...props }) => {
    const context = useRecordContext(props);
    const styles = makeStyles({
        notificationIconSmall: {
            marginTop: 8,
            height: 10,
            width: 10,
            minWidth: 10,
        },
    })();

    return (
        !context.record?.read_at && (
            <NotificationIcon
                title={title}
                colors={{
                    backgroundColor: vfColours.cherryRed,
                    color: vfColours.white,
                }}
                className={styles.notificationIconSmall}
            />
        )
    );
};

UnreadField.propTypes = {
    title: PropTypes.string,
}

export default UnreadField;