import React from 'react';
import { get as lodashGet, isNull as lodashIsNull } from 'lodash';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import SkeletonWrapper from '../../../Components/SkeletonWrapper';
import ProfileAvatar from './ProfileAvatar';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '../../../Components/Buttons/CustomButton';
import { ELEVATION_SELL_PAGE_URL, ROOT_DOMAIN, TOP_RATED_ADVISER_VF } from '../../../constants';
import { makeStyles } from '@material-ui/core';
import { usePermissions } from 'react-admin';
import * as analytics from '../../../analytics';
import { formatPlural } from '../../../utils';
import HeaderProfessionalDetails from '../../../AppLayout/Header/HeaderProfessionalDetails';
import { ExternalLink, Link } from '../../../Components/Links';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 5,
        //to be added back when we remove Guide tracker
        // marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        '& .MuiTypography-root': {
            color: theme.palette.primary.contrastText,
        },
    },
    container: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    avatarWrap: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    personName: {
        marginTop: 0,
        color: theme.palette.secondary.main + ' !important',
        marginBottom: 0,
        fontSize: 32,
    },
    ratingDataWrap: {
        display: 'flex',
        flex: 1,
        padding: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    ratingWrap: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
        borderLeft: `2px solid ${theme.palette.secondary.main}`,
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            borderLeft: 'none',
            textAlign: 'center',
        },
    },
    ratingWrapNoLeftBorder: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            borderLeft: 'none',
            textAlign: 'center',
        },
    },
    ratingText: {
        marginTop: 0,
        marginBottom: 0,
        fontSize: 24,
    },
    button: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
        },
    },
    secondaryText: {
        color: theme.palette.secondary.main,
    },
    topRatedImage: {
        maxHeight: 150,
        maxWidth: 150,
        marginRight: 20,
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'center',
            marginRight: 0,
        },
    },
    rating: {
        marginRight: theme.spacing(1.5),
        marginLeft: theme.spacing(1.5),
    },
    reviewText: {
        textDecoration: 'none',
        marginTop: 0,
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            margin: `${theme.spacing(1)}px 0 0`,
        },
    },
    allTime: {
        color: theme.palette.secondary.main,
        fontSize: 26,
    },
    professionalDetails: {
        '& ul': {
            display: 'inline-flex',
            gap: 15,
            margin: 0,
            padding: 0,
            alignItems: 'center',
            height: 10,

        },
        '& li': {
            position: 'relative',
            top: '-5px',
            '& .MuiTypography-root': {
                margin: 0,
                padding: 0,
            },
        },
    },
    profileState: {
        '& .MuiTypography-root': {
            lineHeight: 0.75,
        },
    },
}));

const getPlanWithLink = (plan) => {
    if (plan !== 'Starter') {
        return  plan;
    }
    return (
        <>
            { plan }
            <Link href={'/payment'}>&nbsp;(Upgrade to Verified)</Link>
        </>
    );
};
const getElevationWithLink = (elevation) => {
    if (elevation) {
        return 'Yes';
    }

    return (
        <>
            No
            <ExternalLink href={ELEVATION_SELL_PAGE_URL} target={'_blank'}>
                &nbsp;(Learn more)
            </ExternalLink>
        </>
    );
};
const WelcomePanel = props => {
    const { firstName, permalink, data, plan } = props;
    let loading = lodashIsNull(firstName) || !data;
    const rating = lodashGet(data, 'overall_rating', 0);
    const classes = useStyles();
    const { permissions } = usePermissions();
    const isTopRated = permissions?.is_top_rated;
    const reviewTextPlural = formatPlural(lodashGet(data, 'review_and_fi_count', 0), 'review', 'reviews');
    const elevation = lodashGet(permissions, 'elevation', false)

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                {!permissions?.elevation_private_mode &&
                    <div className={classes.avatarWrap}>
                        {loading &&
                            <Skeleton
                                variant="circle"
                                height={160}
                                width={160}
                                style={{ marginRight: 24 }}
                            />
                        }
                        {!loading &&
                            <>
                                <ProfileAvatar loading={loading} />
                                <Button
                                    variant="contained"
                                    color="tertiary"
                                    href={`${ROOT_DOMAIN}/${permalink}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() =>
                                        analytics.track('view_profile', {
                                            category: 'home_profile_banner',
                                        })
                                    }
                                    className={classes.button}>
                                    View Profile
                                </Button>
                            </>
                        }
                    </div>
                }
                <div className={classes.ratingDataWrap}>
                    <SkeletonWrapper loading={loading} height={45} width={235}>
                        <div className={!permissions?.elevation_private_mode ? classes.ratingWrap : classes.ratingWrapNoLeftBorder}>
                            <Typography className={`${classes.personName} welcome`} component="h2" variant="h3">
                                {`Hi ${firstName},`}
                            </Typography>

                            {loading ? (
                                <Skeleton animation={'pulse'} height={40} width={180} />
                            ) : (
                                Number(rating) > 0 && (
                                    <Typography
                                        component="h3"
                                        color="secondary"
                                        className={classes.ratingText}>
                                        <b>
                                            Your rating:{' '}
                                            <span className={classes.secondaryText}>{rating} / 5</span>
                                        </b>
                                    </Typography>
                                )
                            )}

                            <Typography variant="body1" color="primary" className={classes.reviewText}>
                                {loading ? (
                                    <Skeleton animation="pulse" width={220} height={25}/>
                                ) : (
                                    <>
                                        <b className={classes.allTime}>
                                            {lodashGet(data, 'review_and_fi_count', 0)}&nbsp;all time&nbsp;
                                            {reviewTextPlural}
                                        </b>
                                        <span>
                                        {` (${lodashGet(
                                            data,
                                            'review_count',
                                            0,
                                        )} from clients and ${lodashGet(
                                            data,
                                            'first_impression_count',
                                            0,
                                        )} from prospects)`}
                                    </span>
                                    </>
                                )}
                            </Typography>

                            <div className={classes.profileState}>
                                <Typography>
                                    <b>Public profile:&nbsp;</b>{getPlanWithLink(plan)}
                                </Typography>
                                <Typography>
                                    <b>Elevation:&nbsp;</b> {getElevationWithLink(elevation)}
                                </Typography>
                                <div className={classes.professionalDetails}>
                                    <HeaderProfessionalDetails isHomePage={true} data={data}/>
                                </div>
                            </div>
                        </div>
                    </SkeletonWrapper>
                </div>
                {isTopRated && (
                    <img alt='Top Rated Adviser Certificate Icon' src={TOP_RATED_ADVISER_VF} className={classes.topRatedImage}/>
                )}
            </div>
        </div>
    )
        ;
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        firstName: basicInfo.first_name,
        permalink: lodashGet(basicInfo, ['permalink', 'url'], null),
        plan: lodashGet(basicInfo, 'plan', null),
    };
};

export default connect(mapStateToProps)(WelcomePanel);
