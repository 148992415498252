import API_KEYS from './api-keys';

export const ROOT_DOMAIN = API_KEYS.consumer_url;
export const SUPPORT_DOMAIN = 'https://support.vouchedfor.co.uk';
export const ASSET_DOMAIN = 'https://assets.vouchedfor.co.uk';
export const GUIDE_DOMAIN = 'https://guide.vouchedfor.co.uk';
export const AWS_PROFESSIONALS_DOMAIN =
    'https://s3.eu-west-1.amazonaws.com/professionals.vouchedfor.co.uk';
const ACCOUNTS_DOMAIN = API_KEYS.accounts_url;
const ABOUT_DOMAIN = 'https://about.vouchedfor.co.uk';

export const TERMS_AND_CONDITIONS = `${ROOT_DOMAIN}/terms-and-conditions`;
export const PRIVACY_POLICY = `${ROOT_DOMAIN}/privacy-policy`;
export const GUIDE_REGISTER = `${GUIDE_DOMAIN}/toprated/info/?utm_source=registerpage`;
export const GUIDE_HELP_URL = `${GUIDE_DOMAIN}/top-rated-adviser-guide-verified/`;
export const ACCOUNTANTS_GUIDE_URL = 'https://guide.vouchedfor.co.uk/2019-accountants';
export const FA_GUIDE_URL =
    'https://guide.vouchedfor.co.uk/2020FinancialAdvisers/TheTimes/HowToQualify?utm_source=registerpage';

export const SUPPORT_URL = {
    HELP_CENTRE:`${SUPPORT_DOMAIN}/category/hzjymiatl9-adviser-support`,

    ENQUIRY: {
        HOW_MANY_TO_EXPECT: `${SUPPORT_DOMAIN}/article/89-how-many-enquiries-can-i-expect`,
        FP_WHAT_YOU_HAVE_TO_DO: `${SUPPORT_DOMAIN}/article/5e85jrmlqd-free-financial-plan`,
        REFUND_POLICY: `${SUPPORT_DOMAIN}/article/102-enquiry-refund-policy`,
    },
    REVIEW: {
        WHAT_WILL_CLIENTS_RECEIVE: `${SUPPORT_DOMAIN}/article/6j0mxi0pm0-what-will-my-clients-receive-when-i-send-them-a-review-request`,
        HOW_TO_INVITE_CLIENTS: `${SUPPORT_DOMAIN}/article/asaxb3tuuj-how-can-i-invite-my-clients-to-review-me`,
        HOW_MANY_TO_SEND: `${SUPPORT_DOMAIN}/article/tig3s63ado-how-many-review-requests-should-i-send`,
        WHY_ARE_REVIEWS_IMPORTANT: `${SUPPORT_DOMAIN}/article/29gbjlt35j-why-are-reviews-so-important`,
        WHAT_IF_MY_FIRM_OR_NETWORK_IS_INVITING_FEEDBACKS_CENTRALLY: `${SUPPORT_DOMAIN}/en/articles/3351682`,
        WHAT_IS_FIRST_IMPRESSION: `${SUPPORT_DOMAIN}/article/72-what-is-a-first-impression`,
    },
    PROFILE: {
        HOW_TO_WRITE_ABOUT_ME: `${SUPPORT_DOMAIN}/article/68t8153a9h-how-to-write-the-perfect-about-me-section`,
        HOW_TO_ADD_EMAIL_SIG: `${SUPPORT_DOMAIN}/article/xaqxiotefm-how-to-add-the-vouchedfor-email-signature`,
    },
    ACCOUNT: {
        HOW_TO_USE_COE: `${SUPPORT_DOMAIN}/en/articles/3276290`,
        HOW_TO_USE_MARKETING_TOOLS: `${SUPPORT_DOMAIN}/article/xaqxiotefm-how-to-add-the-vouchedfor-email-signature`,
        HOW_TO_USE_MARKETING_TOOLS_V2: `${SUPPORT_DOMAIN}/category/uonubddv1i-reputation-tools`,
        VERIFIED_MEMBERSHIP: `${SUPPORT_DOMAIN}/article/8ww4evk65p-the-benefits-of-vouchedfors-verified-and-unlimited-plans`,
        REP_SCORE: `${SUPPORT_DOMAIN}/article/101-what-is-the-reputation-score?preview=5cefb99e2c7d3a1cb9b6fbbf`,
        PRICING_PLANS: `${SUPPORT_DOMAIN}/category/x0a9q9bdw1-our-plans`,
        WHO_CAN_LEAVE_REVIEW: `${SUPPORT_DOMAIN}/article/77-who-can-leave-me-a-review`,
    },
    FEES: {
        ADD_YOUR_FEES: `${SUPPORT_DOMAIN}/article/99-adding-your-fees-to-vouchedfor`,
    },
};

export const PRICING_URL = {
    FINANCIAL_ADVISER: `${ACCOUNTS_DOMAIN}/pricing-financial_adviser`,
    ACCOUNTANT: `${ACCOUNTS_DOMAIN}/pricing-accountant`,
    LEGAL_ADVISER: `${ACCOUNTS_DOMAIN}/pricing-solicitor`,
};

export const REVIEW_URL = {
    EMPLOYEES: `${ACCOUNTS_DOMAIN}/invite-reviews`,
};

export const FIND_ADVISER_URL = {
    FINANCIAL_ADVISER: `${ROOT_DOMAIN}/financial-advisor-ifa`,
    MORTGAGE_ADVISER: `${ROOT_DOMAIN}/mortgage-advisor`,
    ACCOUNTANT: `${ROOT_DOMAIN}/accountant`,
    LEGAL_ADVISER: `${ROOT_DOMAIN}/solicitor`,
};

export const SOCIAL_URL = {
    FACEBOOK: 'https://www.facebook.com/VouchedFor',
    TWITTER: 'https://twitter.com/VouchedFor',
    LINKEDIN: 'https://www.linkedin.com/company/vouchedfor/',
};

export const COMPANY_URL = {
    ABOUT: `${ABOUT_DOMAIN}/about/what-we-do`,
    BLOG: `${ROOT_DOMAIN}/blog`,
    EMAIL_SUPPORT: 'mailto:consumer_support@vouchedfor.co.uk',
    CONTACT_LINK: `${ABOUT_DOMAIN}/about/contact`,
};

export const ACTION_LIST_ESTIMATED_VALUE_CALCULATION_URL = 'https://support.vouchedfor.co.uk/article/3gz2ndixt6-estimated-values-of-commercial-opportunities';
export const ELEVATION_PRICING_URL = `${SUPPORT_DOMAIN}/article/god93s8ujc-elevation-overview`;
export const ELEVATION_SELL_PAGE_URL = `https://professionals.vouchedfor.co.uk/elevation`;