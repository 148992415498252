import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgressWithTextInside, { CircularProgressWithIconInside } from './CircularProgressWithTextInside';
import { GUIDE_AVERAGE_RATING_REQUIREMENT, GUIDE_REVIEW_COUNT_REQUIREMENT } from './GuideTracker';
import { getGuideYear } from '../../../../utils';

const useStyles = makeStyles(theme => ({
    circularProgressLabel: {
        marginInline: 'auto',
        maxWidth: 'fit-content',
    },
    trackerItems: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    twoTrackerItemsWrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.main
    }
}));

const getStepsCompleted = (data) => {
    const { hasPublicProfile, reviewCountCurrentYear, reviewAverageCurrentYear, overallReviewAverage } = data;
    let count = 0;
    if (reviewCountCurrentYear >= GUIDE_REVIEW_COUNT_REQUIREMENT) {
        count++;
    }
    if (reviewAverageCurrentYear >= GUIDE_AVERAGE_RATING_REQUIREMENT) {
        count++;
    }
    if (overallReviewAverage >= GUIDE_AVERAGE_RATING_REQUIREMENT) {
        count++;
    }
    if (hasPublicProfile) {
        count++;
    }

    return count;
};

export default function GuideTrackerProgressBarsV2({ data }) {
    const classes = useStyles();
    const guideYear = getGuideYear();
    const currentYear = guideYear - 1;

    if (!data) {
        return null;
    }

    return (
        <div>
            {getStepsCompleted(data) === 0 &&
                <Typography variant="h5" color={'primary'}>
                    Top-Rated Adviser {guideYear}&nbsp;
                    <span
                        className={classes.trackerInfo}>
                        {getStepsCompleted(data) + '/4 steps completed'}
                    </span>
                </Typography>
            }
            {getStepsCompleted(data) < 4 && getStepsCompleted(data) !== 0 &&
                <Typography variant="h5" color={'primary'}>
                    You already meet&nbsp;
                    <span>
                        {getStepsCompleted(data) + '/4 of the Top Rated Adviser Criteria'}
                    </span>
                </Typography>
            }
            {getStepsCompleted(data) === 4 &&
                <Typography variant="h5" color={'primary'}>
                    Congratulations, you are currently on-track to qualify for our {guideYear} Top-rated Adviser Guide
                </Typography>
            }
            <div className={classes.trackerItems}>
                <div className={classes.twoTrackerItemsWrapper}>
                    <CircularProgressWithLabel
                        label={`10 reviews in ${currentYear}`}
                        text={data.reviewCountCurrentYear}
                        percentage={(data.reviewCountCurrentYear / GUIDE_REVIEW_COUNT_REQUIREMENT) * 100}
                    />
                    <CircularProgressWithLabel
                        label={`4.5 rating in ${currentYear}`}
                        text={data.reviewAverageCurrentYear}
                        percentage={(data.reviewAverageCurrentYear / GUIDE_AVERAGE_RATING_REQUIREMENT) * 100}
                    />
                </div>
                <div className={classes.twoTrackerItemsWrapper}>
                    <CircularProgressWithLabel
                        text={data.overallReviewAverage}
                        label={'4.5  overall rating'}
                        percentage={(data.overallReviewAverage / GUIDE_AVERAGE_RATING_REQUIREMENT) * 100}
                    />
                    {data.hasPublicProfile &&
                        <CircularProgressWithLabel
                            label={'Profile is public'}
                            text={''}
                            percentage={100} classes={classes}/>
                    }
                    {!data.hasPublicProfile &&
                        <div>
                            <CircularProgressWithIconInside value={100}/>
                            <div className={classes.circularProgressLabel}>
                                <Typography variant={'body2'}>
                                    <a href={'/payment'} className={classes.link}>
                                        Go Public
                                    </a>
                                </Typography>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

const CircularProgressWithLabel = ({ percentage, text, label }) => {
    const classes = useStyles();

    return (
        <div>
            <CircularProgressWithTextInside percentage={percentage} text={text}/>
            <Typography className={classes.circularProgressLabel} variant={'body2'}>
                {label}
            </Typography>
        </div>
    );
};


