import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames'

const BoldWhenUnreadWrapper = WrappedComponent => (props) => {
    const styles = makeStyles({unread: {fontWeight: 700}})()

    return (
        <WrappedComponent
            className={cn({[styles.unread]: props.record?.read_at === null})}
            {...props}
        />
    );
}

export default BoldWhenUnreadWrapper;