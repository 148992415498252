import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, get } from 'lodash';
import cn from 'classnames';
import compose from 'recompose/compose';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Notification } from 'react-admin';
import AppHeader from '../AppLayout/AppHeader';
import AppDialog from '../AppLayout/AppDialog';
import Sidebar from '../AppLayout/Sidebar/Sidebar';

import withStyles from '@material-ui/core/styles/withStyles';
import { showSidebar, hideSidebar } from '../state/actions';
import shouldShowSidebar from './Sidebar/shouldShowSidebar';
import { usePermissions } from 'react-admin';
import tokenManager from '../tokenManager';

const sanitiseRestProps = ({ staticContext, menu, dispatch, dashboard, showSidebar, ...props }) =>
    props;

const propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    classes: PropTypes.object,
    className: PropTypes.string,
    customRoutes: PropTypes.array,
    logout: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
    open: PropTypes.bool,
    title: PropTypes.node,
};

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.default,
        minHeight: 'auto',
        position: 'relative',
        zIndex: 1,
    },
    appFrame: {
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
    },
    contentWithSidebar: {
        display: 'flex',
        flexGrow: 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 2,
        backgroundColor: '#ffffff',
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
        },
        [theme.breakpoints.up('sm')]: {
            width: 'calc(100vw - 284px)',
        },
        [theme.breakpoints.down('sm')]: {
            overflow: 'hidden',
            padding: 0,
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '3em',
        },
    },
    noPaddingContent: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 2,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100vw - 284px)',
        },
        [theme.breakpoints.up('xs')]: {
            width: 'calc(100vw)',
        },
    },
});

const Layout = props => {
    const { children, classes, className, isLoggedIn, location, basicProfileComplete, showLayoutSidebar, ...rest } = props;
    const externalPage = ['/login', '/register', '/sign-up', '/2fa'].includes(location.pathname);
    const contentWrapperClass =
        isLoggedIn && !externalPage
            ? 'content'
            : 'noPaddingContent';
    const dispatch = useDispatch();
    const { permissions } = usePermissions();
    const sidebarVisible = shouldShowSidebar(location.pathname, basicProfileComplete);

    useEffect(() => {
        if (sidebarVisible && isLoggedIn && !showLayoutSidebar) {
            dispatch(showSidebar());

            return
        }

        if ((!sidebarVisible || !isLoggedIn) && showLayoutSidebar) {
            dispatch(hideSidebar());
        }
    }, []);

    return (
        <div className={cn('layout', classes.root, className)} {...sanitiseRestProps(rest)}>
            <div className={classes.appFrame}>
                <AppHeader title={props.title} open={props.open} logout={props.logout} />

                <main className={classes.contentWithSidebar}>
                    {showLayoutSidebar && !externalPage && <Sidebar />}
                    <div className={classes[contentWrapperClass]}>{children}</div>
                </main>

                <Notification />

                { permissions && !isEmpty(permissions) && !tokenManager.isTwoFactor() ? <AppDialog /> : null }
            </div>
        </div>
    );
};

Layout.propTypes = propTypes;

const mapStateToProps = ({ layout, basicInfo }) => {
    return {
        showLayoutSidebar: get(layout, 'showSidebar'),
        isLoggedIn: get(basicInfo, 'is_logged_in', false),
        basicProfileComplete: get(basicInfo, 'permissions.basic_profile_complete', true),
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, { showSidebar, hideSidebar})
)(withStyles(styles)(Layout));
