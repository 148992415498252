import React from 'react';
import { Route } from 'react-router-dom';
import Register from './Register/Register';
import ForgottenPassword from './ForgottenPassword/ForgottenPassword';
import ResetPassword from './ForgottenPassword/ResetPassword';
import OnboardingUpdatePassword from './ForgottenPassword/OnboardingUpdatePassword';
import ViewEnquiry from './ViewEnquiry/ViewEnquiry';
import VerifyEmail from './VerifyEmail/VerifyEmail';
import SettingsBilling from './SettingsBilling';
import ReviewSetting from './Review/ReviewSetting';
import Firm from './Firm';
import ElevationDashboard from './Dashboard/ElevationDashboard'
import ThankYou from './ThankYou';
import TwoFa from './2fa';
import Review from './Review';
import {
    withPermissionsAndAuthCheck,
    with2faCheck,
} from './Components/Auth/withPermissionsAndAuthCheck';
import ElevationClientAnswers from './Elevation/ElevationClientAnswers';

let customRoutes = [
    <Route noLayout exact path="/2fa" component={TwoFa} />,
    <Route exact path="/firm/reputation" component={withPermissionsAndAuthCheck(Firm.tools)} />,
    <Route exact path="/payment" component={withPermissionsAndAuthCheck(SettingsBilling.directDebit)} />,
    <Route exact path="/invite-reviews" component={withPermissionsAndAuthCheck(Review.invite)} />,
    <Route exact path="/register" component={with2faCheck(Register)} />,
    <Route exact path="/sign-up" component={with2faCheck(Register)} />,
    <Route exact path="/forgot-password" component={with2faCheck(ForgottenPassword)} />,
    <Route exact path="/forgot-password/reset/:token" component={ResetPassword} />,
    <Route exact path="/onboarding/update-password/:token" component={OnboardingUpdatePassword} />,
    <Route exact path="/enquiry/view/:token" component={ViewEnquiry} />,
    <Route exact path="/verify/:token/:professional" component={VerifyEmail} />,
    <Route exact path="/review-setting/template" component={with2faCheck(ReviewSetting.template)} />,
    <Route exact path="/thankyou" component={ThankYou.show} />,
    <Route exact path="/elevation-dashboard" component={withPermissionsAndAuthCheck(ElevationDashboard, 'elevation')} />,
    <Route exact path="/elevation-client-answers" component={withPermissionsAndAuthCheck(ElevationClientAnswers, 'elevation')} />,
]

export default customRoutes;