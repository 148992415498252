import { Tooltip as MuiTooltip, Zoom } from '@material-ui/core';
import { vfColours } from '../MyTheme';
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import cn from 'classnames'

const styles = () => ({
    tooltip: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        textAlign: 'center',
        lineHeight: 1.6,
        fontSize: '0.825rem',
        fontWeight: 700,
        borderRadius: '100%',
        width: 20,
        height: 20,
        margin: '0 4px',
    }
})

const NotificationIcon = ({ symbol, colors, title, className, classes}) => {
    return (
        <MuiTooltip
            title={title}
            placement={'top'}
            TransitionComponent={Zoom}
            arrow
        >
            <span
                className={cn(classes.tooltip, className)}
                style={{
                    backgroundColor: colors?.backgroundColor ? colors.backgroundColor : vfColours.grassGreen,
                    color: colors?.color ? colors.color : vfColours.navyBlue,
                }}
            >
                { symbol }
            </span>
        </MuiTooltip>
    )
}

export default withStyles(styles)(NotificationIcon)
