import React from 'react';
import { Responsive, Datagrid, TextField, DateField } from 'react-admin';
import BoldWhenUnreadWrapper from '../../Components/DataGrid/BoldWhenUnreadWrapper';
import DashboardCardTooltip from "../../Dashboard/DashboardV2/CardComponents/DashboardCardTooltip";
import ReviewListSmall from '../../Review/Content/ReviewListSmall';
import ReviewPageWrapper from '../ReviewPageWrapper';
import ReviewsFilter from '../ReviewsFilter';
import VerifyAndRejectReviewButtons from '../Content/VerifyAndRejectReviewButtons';
import UnreadField from '../../Components/DataGrid/UnreadField';

const TITLE = 'First Impressions';
const GRID_TITLE = 'Your First Impressions';
const TOOLTIP_TITLE = `This represents the answer provided by the reviewer to the question “Are you going to use this advisers services in future?“ The options are Yes, No and Maybe.`;

const BoldWhenUnreadDateField = BoldWhenUnreadWrapper(DateField);
BoldWhenUnreadDateField.defaultProps = DateField.defaultProps;

const BoldWhenUnreadTextField = BoldWhenUnreadWrapper(TextField);
BoldWhenUnreadTextField.defaultProps = DateField.defaultProps;



const FirstImpressionsListWrapper = props => {
    return (
        <Datagrid {...props}>
            <UnreadField label='Unread' title='Read this first impression' source="read_at" />
            <BoldWhenUnreadDateField label="Date" source="submitted_at" />
            <BoldWhenUnreadTextField label="Reviewer name" source="customer.name" />
            <BoldWhenUnreadTextField label="Reviewer email" source="customer.email" />
            <BoldWhenUnreadTextField
                label="Prospect intention"
                source="future_use"
                sortable={false}
            />
            <BoldWhenUnreadTextField label={<DashboardCardTooltip title={TOOLTIP_TITLE} />} />
            <BoldWhenUnreadTextField
                label="Review Status"
                source="state_description"
                sortable={false}
            />
            <VerifyAndRejectReviewButtons reviewType="first_impression" />
        </Datagrid>
    );
}

const FirstImpressionsList = props => (
    <ReviewPageWrapper
        variant="list"
        {...props}
        filters={<ReviewsFilter />}
        sort={{ field: 'submitted_at', order: 'DESC' }}
    >
        <Responsive
            medium={<FirstImpressionsListWrapper />}
            small={
                <ReviewListSmall
                    title={TITLE}
                    subTitle={GRID_TITLE}
                    primaryTextKey="customer.name"
                    secondaryTextKey="customer.email"
                    type="first_impression"
                />
            }
        />
    </ReviewPageWrapper>
);

export default FirstImpressionsList;
