import React, { Component } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PageTabs from '../../AppLayout/PageTabs';
import { getProfessionalTabs } from '../professionalUtils';

class ProfessionalTabs extends Component {
    state = {
        tabsArray: null,
        allowedRoutes: [],
    };

    componentDidMount() {
        const { vertical, professionalId, notifications } = this.props;

        if (!this.state.tabsArray) {
            const tabsArray = getProfessionalTabs(vertical, professionalId, notifications);

            this.setState({
                tabsArray,
                allowedRoutes: tabsArray.map(tab => tab.link),
            });
        }
    }

    render() {
        const { tabsArray, allowedRoutes } = this.state;

        if (
            allowedRoutes.length > 0 &&
            !allowedRoutes.includes(window.location.pathname)
        ) {
            return <Redirect to="/404" />;
        }

        if (tabsArray) {
            return <PageTabs routes={tabsArray} />;
        }

        return null;
    }
}

const mapStateToProps = ({ basicInfo }) => {
    return {
        professionalId: get(basicInfo, 'id'),
        vertical: get(basicInfo, ['permissions', 'vertical']),
        notifications: {
            needsDetails: !get(basicInfo, 'gender')
        }
    }
}

export default connect(mapStateToProps)(ProfessionalTabs);
