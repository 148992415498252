import React from 'react';
import _ from 'lodash';
import { Query } from 'react-admin';
import CustomCard from './CustomCard';
import { makeStyles, Typography } from '@material-ui/core';
import Button from '../../../Components/Buttons/CustomButton';

const useStyles = makeStyles(theme => ({
    cardsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        height: 260,
        flexWrap: 'wrap',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(8),
        [theme.breakpoints.down('md')]: {
            height: 'auto',
            justifyContent: 'space-around',
        },
    },
    explanation: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .MuiTypography-h5': {
            marginTop: theme.spacing(1),
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            textAlign: 'center',
            justifyContent: 'space-around',
        },
    },
    subtext: {
        color: theme.palette.secondary.main,
    },
}));

const DashboardStatistics = ({ handleCardClick, cards }) => {
    const classes = useStyles();

    return (
        <Query type="getOne" resource="dashboard/statistics">
            {({ data, loading }) => {
                if (!loading && data) {
                    if (_.get(data, 'statistics', []).length === 0) {
                        return null;
                    }
                }

                return (
                    <>
                        <div className={classes.explanation}>
                            <Typography color="primary" variant="h4" component="h4">
                                See the impact your reviews are having{' '}<span className={classes.subtext}>(Last 12 months)</span>
                            </Typography>
                        </div>

                        <div className={classes.cardsWrapper}>
                            {cards.map(d => (
                                <CustomCard
                                    onClick={() => handleCardClick(d.gka)}
                                    active={d.active}
                                    title={d.title}
                                    subtitle={d.subtitle}
                                    value={d.number}
                                    main={true}
                                />
                            ))}
                        </div>
                    </>
                );
            }}
        </Query>
    );
};

export default DashboardStatistics;
