import React, { Component } from 'react';
import _ from 'lodash';
import dataProvider from '../../dataProvider';
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';
import DowngradeText from './DowngradeText';
import LabelAdornment from '../../Components/Typography/LabelAdornment';
import { planUpgradeSuccess } from '../../state/actions';
import { FormSectionTitle } from '../../Components/Typography/Headlines';
import { PROFESSIONAL, CONTACT } from '../../constants';

import Typography from '@material-ui/core/Typography';
import Button from '../../Components/Buttons/CustomButton';
import withStyles from '@material-ui/core/styles/withStyles';

const { FINANCIAL_ADVISER, ACCOUNTANT } = PROFESSIONAL.VERTICAL;

const styles = theme => ({
    detailSection: {
        marginTop: theme.spacing(1),
    },
});

class AccountSettingsContent extends Component {
    onUpgradeClick = async () => {
        const { permissions, history, showNotification, planUpgradeSuccess } = this.props;

        if (permissions.requires_billing_details) {
            return history.push('/payment');
        } else {
            try {
                await dataProvider('CREATE', 'professional/upgrade', {});

                showNotification('Upgrade success!');

                planUpgradeSuccess();
            } catch {
                showNotification(
                    'Sorry, we had an error upgrading your account. Please try again',
                    'warning'
                );
            }
        }
    };

    renderAuthoritySection = () => {
        const { data, classes } = this.props;
        const vertical = _.get(data, 'vertical');
        const personalFcaNumber = _.get(data, 'personal_fca_number');
        const firmFcaNumber = _.get(data, 'firm_fca_number');
        const sraNumber = _.get(data, 'sra_number');
        const type = vertical === FINANCIAL_ADVISER ? 'FCA' : 'SRA';

        if (vertical === ACCOUNTANT) return null;

        const uppercaseValue = value => {
            return value ? String(value).toUpperCase() : undefined;
        };

        return (
            <>
                <FormSectionTitle title={`Your ${type} details`} />
                <Typography variant="body1">
                    To change these, please contact us on {CONTACT.PHONE_NUMBER}
                </Typography>
                <div className={classes.detailSection}>
                    <LabelAdornment label="FCA ID:" value={uppercaseValue(personalFcaNumber)} />
                    <LabelAdornment label="Firm FCA ID:" value={uppercaseValue(firmFcaNumber)} />
                    <LabelAdornment label="SRA ID:" value={uppercaseValue(sraNumber)} />
                </div>
            </>
        );
    };

    render() {
        const { permissions, classes, data } = this.props;
        const planName = _.get(data, ['plan', 'name']);
        const planGka = _.get(data, ['plan', 'gka']);

        return (
            <div>
                <FormSectionTitle title="Plan Details" />
                <Typography variant="body1" gutterBottom>
                    <b>Public Profile: </b> {planName}
                </Typography>
                <div className={classes.detailSection}>
                    {_.get(permissions, 'can_upgrade', false) ? (
                        <Button variant="contained" color="tertiary" onClick={this.onUpgradeClick}>
                            Upgrade Plan
                        </Button>
                    ) : (
                        <DowngradeText planGka={planGka} />
                    )}
                </div>
                {this.renderAuthoritySection()}
            </div>
        );
    }
}

export default connect(
    null,
    { showNotification, planUpgradeSuccess }
)(withStyles(styles)(AccountSettingsContent));
