import React from 'react';
import { FormSectionTitle } from '../Components/Typography/Headlines';
import { SimpleShowLayout } from 'react-admin';
import ReputationToolContent from './ReputationToolContent';
import ProfessionalReputationToolGrid from './ProfessionalReputationToolGrid';

import Card from '@material-ui/core/Card';

const ProfessionalReputationToolList = () => {

    return (
        <Card>
                <SimpleShowLayout>
                    <>
                        <FormSectionTitle title="Reputation Tools" style={{ fontSize: '30px' }} />
                        <ReputationToolContent />
                        <ProfessionalReputationToolGrid />
                    </>
                </SimpleShowLayout>
        </Card>
    );
};

export default ProfessionalReputationToolList;
